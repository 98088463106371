<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
    let that = this;
    let widthPresets = 800, heightPresets = 232, widthPresetsLimitation = 1920, heightPresetsLimitation = 1080, type = true;

    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      this.$router.push('/mobile');
      widthPresets = 375;
      heightPresets = 667;
      widthPresetsLimitation = 414;
      heightPresetsLimitation = 896;
      type = false;
      // $('hmtl, body').css({'min-width': '375px', 'min-height': '667px'});
      // that.$message({
      //   message: '请使用Web端访问，移动端请待网站公告',
      //   type: 'warning',
      //   onClose(){
      //     that.$router.go(-1);
      //   }
      // });
    }
    
    document.body.style.minWidth = widthPresets + 'px';
    document.body.style.minHeight = heightPresets + 'px';

    let sizeHeight = parseFloat(widthPresets / heightPresets).toFixed(8);
    let percentageX = parseFloat(document.body.clientWidth / widthPresetsLimitation).toFixed(8);
    let percentageY = percentageX;

    if (sizeHeight < percentageX) {
      percentageY = parseFloat(document.body.clientHeight / heightPresetsLimitation).toFixed(8);
    }

    if (type) document.getElementById('app').setAttribute('style', 'transform: scale(' + percentageX + ', ' + percentageY + ');width: ' + widthPresetsLimitation + 'px;height: ' + (1080 - (document.body.clientHeight - 1080)) + 'px;');
    else document.getElementById('app').setAttribute('style', 'transform: scale(' + percentageX + ', ' + percentageY + ');width: ' + widthPresetsLimitation + 'px;');

    window.onresize = () => {
      percentageX = parseFloat(document.body.clientWidth / widthPresetsLimitation).toFixed(8);
      percentageY = percentageX;

      if (sizeHeight < parseFloat(document.body.clientWidth / document.body.clientHeight).toFixed(8)) {
        percentageY = parseFloat(document.body.clientHeight / heightPresetsLimitation).toFixed(8);
      }

      document.getElementById('app').setAttribute('style', 'transform: scale(' + percentageX + ', ' + percentageY + ');width: ' + widthPresetsLimitation + 'px;');
    };
  }
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  // min-width: 800px;
  // min-height: 232px;
  // max-height: 1080px;
  margin: 0;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: BenMoYouYuan;
  transform: scale(1);
  transform-origin: 0 0;
  // width: 1920px;
  height: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@font-face {
  font-family: BenMoYouYuan;
  src: url('@/../public/font/BenMoYouYuan.ttf');
}
</style>
