import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login/login'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('@/views/../Mobile/Home/home'),
  },
  {
    path: '/mobile/login',
    name: 'mobile/login',
    component: () => import('@/views/../Mobile/Login/login'),
  }
];

const router = new VueRouter({
  mode: 'history',
  // base: '/zzy/',
  routes,
});

export default router;
